import React from "react";
import { Container} from "react-bootstrap";

function Footer() {
  return (
    <Container fluid className="footer">
    </Container>
  );
}

export default Footer;
